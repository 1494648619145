import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "change-pwd"
};
const _hoisted_2 = {
  class: "change-pwd__box"
};
import { router } from '@/router';
export default {
  __name: 'ChangePassword',
  setup(__props) {
    const onClickLeft = () => {
      router.back();
    };
    const jump = path => {
      router.push({
        path
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-grey__bg",
        fixed: "",
        placeholder: "",
        title: _ctx.$t('Change password'),
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "change-pwd__item mb-12",
        onClick: _cache[0] || (_cache[0] = $event => jump('/loginPwd'))
      }, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Changing the Login Password')), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })]), _createElementVNode("div", {
        class: "change-pwd__item",
        onClick: _cache[1] || (_cache[1] = $event => jump('/withdrawalPwd'))
      }, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Change the withdrawal password')), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })])])]);
    };
  }
};